/* eslint-disable max-lines */
import {
    addDays,
    addMinutes,
    addYears,
    differenceInDays,
    differenceInYears,
    format,
    intervalToDuration
} from 'date-fns';
import {
    de as de_DE,
    enGB as en_GB,
    nl as nl_NL
} from 'date-fns/locale';

export const locales = {
    de_DE,
    en_GB,
    nl_NL
};

export const saasDateState = {
    dateFormat: 'yyyy-MM-dd',
    labelFormat: 'EEEE',
    locale: 'nl_NL',
    timeFormat: 'HH:mm'
};

/** @namespace Pwasaas/Util/Saas/Date/SaasDate */
export class SaasDate {
    __construct() {
        const {
            dateFormat,
            labelFormat,
            locale,
            timeFormat
        } = saasDateState;

        this.date = new Date();
        this.dateFormat = dateFormat;
        this.labelFormat = labelFormat;
        this.locale = locale;
        this.timeFormat = timeFormat;
    }

    /**
     * Returns a formatted date instance
     *
     * @param {String} date
     * @param {String} format
     * @param {String} divider
     * @returns {Date}
     */
    _getFormattedDateInstance(
        date,
        format,
        divider = '-'
    ) {
        const dates = date.split(divider);
        const formats = format.split(divider);
        const dateOrder = ['yyyy', 'MM', 'dd'].map((current) => {
            const index = formats.findIndex((value) => value === current);

            if (index === -1) {
                throw new Error('Date format incorrect! Use "dd", "MM", and "yyyy"');
            }

            if (!dates[index]) {
                throw new Error('Date value incorrect! Use "01", "12" and "1999"');
            }

            return dates[index];
        });

        return new Date(
            dateOrder[0],
            dateOrder[1] - 1,
            dateOrder[2]
        );
    }

    /**
     * Returns object with duration left
     *
     * @typedef {Object} DateUnits
     * @property {Number} days
     * @property {Number} hours
     * @property {Number} minutes
     * @property {Number} months
     * @property {Number} seconds
     * @property {Number} years
     *
     * @param {String} dateTo
     * @param {String} format
     * @returns {DateUnits}
     */
    getCountdown(
        dateTo,
        format = this.dateFormat
    ) {
        const interval = {
            start: this.date,
            end: this._getFormattedDateInstance(dateTo, format)
        };

        return intervalToDuration(interval);
    }

    /**
     * @param {Number} amount
     * @returns {SaasDate}
     */
    addDays(amount) {
        const date = this.getDateWithRange(amount, 'days');

        return this.setDate(date);
    }

    /**
     * @param {Number} amount
     * @returns {SaasDate}
     */
    addMinutes(amount) {
        const date = this.getDateWithRange(amount, 'minutes');

        return this.setDate(date);
    }

    /**
     * Returns a formatted date
     *
     * @returns {String}
     */
    getDate() {
        return format(this.date, this.dateFormat);
    }

    /**
     * Returns date based on the given range and unit
     *
     * @param {Number} amount
     * @param {String} unit
     * @returns {Date}
     * @throws {Error} Will throw an error when unit is not valid
     */
    getDateWithRange(
        amount,
        unit
    ) {
        switch (unit) {
        case 'days':
            return addDays(this.date, amount);
        case 'minutes':
            return addMinutes(this.date, amount);
        case 'years':
            return addYears(this.date, amount);
        default:
            throw new Error(`'${unit}' is not valid. Specify a valid unit (days, minutes, years)`);
        }
    }

    /**
     * Returns formatted date label based
     * on current locale and label format
     *
     * @returns {String}
     */
    getDateLabel() {
        return format(this.date, this.labelFormat, {
            locale: locales[this.locale]
        });
    }

    /**
     * @param {String} date
     * @param {String} format
     * @param {String} unit
     * @returns {Number}
     * @throws {Error} Will throw an error when unit is not valid
     */
    getDifference(
        date,
        format = this.dateFormat,
        unit = 'years'
    ) {
        const compareDate = this._getFormattedDateInstance(date, format);

        switch (unit) {
        case 'days':
            return differenceInDays(this.date, compareDate);
        case 'years':
            return differenceInYears(this.date, compareDate);
        default:
            throw new Error(`'${unit}' is not valid. Specify a valid unit (days, years)`);
        }
    }

    /**
     * @returns {String}
     */
    getTime() {
        return format(this.date, this.timeFormat);
    }

    /**
     * @param {Date} date
     * @returns {SaasDate}
     */
    setDate(date) {
        this.date = date;

        return this;
    }

    /**
     * @param {String} date
     * @param {String} format
     * @returns {SaasDate}
     */
    setDateByFormat(
        date,
        format = this.dateFormat
    ) {
        return this.setDate(this._getFormattedDateInstance(date, format));
    }

    /**
     * @param {String} dateFormat
     * @returns {SaasDate}
     */
    setDateFormat(dateFormat) {
        this.dateFormat = dateFormat;

        return this;
    }

    /**
     * @param {String} labelFormat
     * @returns {SaasDate}
     */
    setLabelFormat(labelFormat) {
        this.labelFormat = labelFormat;

        return this;
    }

    /**
     * @param {String} locale
     * @returns {SaasDate}
     */
    setLocale(locale) {
        this.locale = locale;

        return this;
    }

    /**
     * @param {String} timeFormat
     * @returns {SaasDate}
     */
    setTimeFormat(timeFormat) {
        this.timeFormat = timeFormat;

        return this;
    }
}

export default SaasDate;
