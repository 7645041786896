/**
 * Add product stock status above the add to cart wrapper, and disable on grouped products
 */

import {
    ADD_TO_CART,
    SHOW_PRODUCT_STOCK_STATUS
} from 'Component/ProductActions/ProductActions.config';
import { GROUPED } from 'Util/Product/Types';

import ProductStock from '../component/ProductStock';
import { getStockStatus } from '../util/ProductStock';

import '../style/ProductActions.manual.style';

export class ProductActionsComponent {
    actionsRenderMap = (originalMember, instance) => {
        const {
            product: {
                type_id
            } = {},
            showProductStockStatus
        } = instance.props;

        if (!showProductStockStatus || type_id === GROUPED) {
            return originalMember;
        }

        return Object.entries(originalMember).reduce(
            (renderMap, [type, render]) => {
                if (type === ADD_TO_CART) {
                    return {
                        ...renderMap,
                        [SHOW_PRODUCT_STOCK_STATUS]: this.renderProductStockStatus.bind(instance),
                        [type]: render
                    };
                }

                return {
                    ...renderMap,
                    [type]: render
                };
            }, {}
        );
    };

    renderProductStockStatus() {
        const {
            backorders,
            product: {
                stock_item,
                stock_item: {
                    in_stock
                } = {},
                stock_status
            } = {},
            showProductStockIcon,
            showProductStockLabel,
            showProductStockStatus,
            showProductStockWhenInStock
        } = this.props;

        if (!showProductStockStatus || (!in_stock && showProductStockWhenInStock)) {
            return null;
        }

        return (
            <ProductStock
              isWithIcon={ showProductStockIcon }
              isWithLabel={ showProductStockLabel }
              mix={ {
                  block: 'ProductActions',
                  elem: 'ProductStock'
              } }
              status={ getStockStatus(stock_item, backorders, stock_status) }
            />
        );
    }
}

const {
    actionsRenderMap
} = new ProductActionsComponent();

export default {
    'Pwasaas/Component/ProductActions/Component/ProductActionsComponent': {
        'member-property': {
            actionsRenderMap: {
                position: 200,
                implementation: actionsRenderMap
            }
        }
    }
};
