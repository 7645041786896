/* eslint-disable max-lines */
import {cloneElement} from 'react';

import Field from 'Component/Field';
import Loader from 'Component/Loader';

import '../style/SaasCheckoutForm.manual.style';

export class SaasCheckoutFormComponent {
    componentDidMount = (args, callback, instance) => {
        const {
            default_country,
            onInitialDataLoad
        } = instance.props;

        if (document.querySelector('input#vat_id') !== null) {
            const vatIdInput = document.querySelector('input#vat_id');
            const vatIdInputParent = vatIdInput.parentElement;
            const vatIdInputWrapper = document.createElement('div');
            const vatIdInputWrapperSpan = document.createElement('span');
            vatIdInputWrapper.classList.add('vat_id_country_wrapper');
            vatIdInputWrapperSpan.classList.add('vat_id_country_label');
            vatIdInputWrapperSpan.setAttribute('id', 'vat_id_country_label');
            vatIdInputWrapperSpan.innerHTML = default_country;
            vatIdInputWrapper.appendChild(vatIdInputWrapperSpan);

            vatIdInputWrapper.appendChild(vatIdInput);
            vatIdInputParent.appendChild(vatIdInputWrapper);
        }

        callback(...args);
    };

    onCountryChange = (args, callback, instance) => {
        const [countryId] = args;

        if (document.querySelector('span#vat_id_country_label') !== null) {
            const vatIdCountryLabel = document.querySelector('span#vat_id_country_label');
            vatIdCountryLabel.innerHTML = countryId;
        }

        return callback(...args);
    };
}

const {
    componentDidMount,
    onCountryChange,
} = new SaasCheckoutFormComponent();

export default {
    'Component/CheckoutAddressForm/Component': {
        'member-function': {
            componentDidMount,
            onCountryChange,
        }
    }
};
