import { cloneElement } from 'react';

import { renderInputWithLabel, renderLabel } from '../util/Form';

export class AfterpayIn3Component {
    renderGenders = (args, callback, instance) => {
        const genders = [
            {
                code: 'mr',
                label: __('Mr.')
            },
            {
                code: 'mrs',
                label: __('Mrs.')
            }
        ];

        const id = 'afterpayin3-genders';
        const label = __('Gender');
        const block = 'AfterpayIn3Select';

        return (
            <div block={ block }>
                { renderLabel(block, true, id, label) }

                <select
                    block={ block }
                    elem="Select"
                    id={ id }
                    name="gender"
                    onChange={ this.updateGender }
                >
                    { this.renderPlaceholder() }
                    { genders.map(this.renderGenderOption) }
                </select>
            </div>
        );
    }

    /**
     *
     * @returns {JSX.Element}
     */
    renderPlaceholder() {
        return (
            <option value="xxx" label={ __('Choose your gender...') } />
        );
    }

    /**
     *
     * @param item
     * @returns {JSX.Element}
     */
    renderGenderOption = (item) => {
        const { code, label } = item;

        return (
            <option
                key={ `gender_${ code}` }
                id={ `gender_${ code}` }
                value={ code }
            >
                { label }
            </option>
        );
    }
}

const {
    renderGenders
} = new AfterpayIn3Component();

export default {
    'ScandipwaMultisafepayPaymentIntegration/Component/AfterpayIn3/Component/AfterpayIn3Component': {
        'member-function': {
            renderGenders
        }
    }
};
