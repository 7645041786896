import {
    IN_BACKORDER_LABEL,
    IN_STOCK_LABEL,
    OUT_OF_STOCK_LABEL
} from '../../component/ProductStock/ProductStock.config';

export const OUT_OF_STOCK = 'OUT_OF_STOCK';

/** @namespace Pwasaas/Plugin/ShowProductStock/Util/ProductStock/Index/getStockStatus */
export const getStockStatus = (stock_item = {}, backorders, stock_status) => {
    const { in_stock, qty } = stock_item;

    // orderable status of a product is determined by stock status
    // if a product is out of stock (not the stock item status)
    // then the product is out of stock
    if (stock_status === OUT_OF_STOCK) {
        return OUT_OF_STOCK_LABEL;
    }

    if (in_stock) {
        if (qty > 0) {
            return IN_STOCK_LABEL;
        }

        if (backorders) {
            return IN_BACKORDER_LABEL;
        }
    }

    return IN_STOCK_LABEL;
};
