import { DATE_OF_BIRTH, DATE_OF_BIRTH_FIELD } from 'Component/CheckoutDateOfBirth/CheckoutDateOfBirth.config';

export const ADDITION = 'street2';
export const CITY = 'city';
export const COMPANY = 'company';
export const COUNTRY = 'country_id';
export const FIRSTNAME = 'firstname';
export const HOUSENUMBER = 'street1';
export const LASTNAME = 'lastname';
export const POSTCODE = 'postcode';
export const REGION = 'region_string';
export const STREET = 'street0';
export const TELEPHONE = 'telephone';
export const VAT = 'vat_id';

export const OPTIONAL = 'opt';
export const REQUIRED = 'req';

export const EDIT_FIELDS = {
    [ADDITION]: {
        label: __('Addition'),
        validation: [],
        validateSeparately: false
    },
    [CITY]: {
        label: __('Place')
    },
    [TELEPHONE]: {
        label: __('Telephone')
    },
    [COMPANY]: {
        label: __('Company (not mandatory)'),
        validation: ['notEmpty'],
        validateSeparately: false
    },
    [COUNTRY]: {
        type: 'hidden'
    },
    [HOUSENUMBER]: {
        label: __('House number'),
        validation: ['notEmpty', 'houseNumber'],
        validateSeparately: false
    },
    [REGION]: {
        type: 'hidden'
    },
    [STREET]: {
        label: __('Street name')
    },
    [VAT]: {
        label: __('VAT Number (not mandatory)'),
        validation: ['notEmpty'],
        validateSeparately: false,
        type: 'hidden'
    },
    ...DATE_OF_BIRTH_FIELD
};

export const AVAILABLE_FIELDS = [
    ADDITION,
    CITY,
    COMPANY,
    COUNTRY,
    DATE_OF_BIRTH,
    FIRSTNAME,
    HOUSENUMBER,
    LASTNAME,
    POSTCODE,
    REGION,
    STREET,
    TELEPHONE,
    VAT
];
